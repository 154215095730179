* article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:700;}dfn{font-style:italic;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace, serif;font-size:1em;}pre{white-space:pre-wrap;word-wrap:break-word;}q{quotes:\201C \201D \2018 \2019;}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-.5em;}sub{bottom:-.25em;}img{border:0;}svg:not(:root){overflow:hidden;}fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,html input[type=button],/* 1 */
input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer;}button[disabled],input[disabled]{cursor:default;}input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0;}input[type=search]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}body,figure{margin:0;}legend,button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}

.clearfix:after {visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0; }

* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

/* --- Global Styles ---*/

body, html {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	letter-spacing: 2px;
	line-height: 30px;
	color: #4D727E;
	font-weight: 300;
}

@font-face {
    font-family: YorkshireBrushScript;
    src: url(../fonts/YorkshireBrushScript-Regular.otf);
    src: url(../fonts/YorkshireBrushScript-Regular.woff);
}

.wrapper {
	width: 1200px;
	max-width: 90%;
	margin: 0 auto;
}

/*-- START OF HEADER STYLES --*/

#main-header {
	background-color: #ffffff;
	box-shadow: 0 0 5px 0 #888888;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1001;
}

.header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
}

.logo {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 300;
	color: #1f435f;
	text-decoration: none;
}

.logo span {
	color: #79a8a9;
	font-weight: 500;
}

.main-nav a {
	text-decoration: none;
	text-transform: uppercase;
	color: #4d7273;
	font-size: 12px;
	font-weight: 300;
	margin-left: 30px;
	padding-bottom: 10px;
}

.social {
	display: inline;
}

.social a {
	margin-left: 10px;
}

.social a:first-child {
	margin-left: 30px;
}

.active {
	border-bottom: 4px solid #e8e485;
}

.menu-trigger {
	width: 35px;
	height: auto;
	display: none;
}


/*-- END OF HEADER STYLES --*/

/*-- START OF LANDING PAGE STYLES --*/

#landing {
	background: linear-gradient(rgba(31, 78, 95, 0.8), rgba(31, 78, 95, 0.8)), url("/images/fountain-pen.jpg");
	background-size: cover;
	background-attachment: fixed;
	height: 900px;
}

.landing-info {
	padding-top: 200px;
	height: 800px;
	text-align: center;
}

.main-title {
	font-family: "YorkshireBrushScript", cursive;
	font-size: 120px;
	color: #f4f7f7;
	letter-spacing: 6px;
	line-height: 1em;
	margin: 0;
	padding-bottom: 60px;
}

.sub-title-top, .sub-title-bottom {
	font-size: 30px;
	font-weight: 300;
	color: #f4f7f7;
	letter-spacing: 4px;
	line-height: 1.2em;
	margin-top: 0;
	max-width: 100%;
}

.sub-title-bottom span {
	font-style: italic;
	font-weight: bold;
}

.btn {
	text-decoration: none;
	text-transform: uppercase;
	color: #1f435f;
	font-size: 14px;
	font-weight: 500;
	background-color: #e8e485;
	padding: 10px 20px;
	border-radius: 5px;
}

.btn:hover {
	background-color: #f4f7f7;
	transition: ease-in 0.3s;
	color: #4d7273;
}



/*-- END OF LANDING PAGE STYLES --*/


/*-- START OF SERVICES SECTION STYLES --*/

#services {
	padding-top: 100px;
}

.story-analysis-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.secondary-title h2 {
	font-size: 90px;
	line-height: 1.2em;
	font-weight: 300;
}

.secondary-title {
	width: 40%;
	margin-right: 10%;
}

.description {
	width: 50%;
}

h4 {
	font-size: 30px;
	font-weight: 500;
	letter-spacing: 2px;
	line-height: 40px;
	margin: 0;
}

.story-analysis-features {
	margin: 150px 0;
	display: flex;
	justify-content: space-between;
}

.feature-container {
	padding: 0 20px;
	color: #4d727e;
	width: 28%;
	text-align: center;
}

.feature-image {
	width: 100px;
	height: auto;
}

.feature-description p {
	font-size: 18px;
	line-height: 28px;
}

.feature-description h5 {
	font-size: 26px;
	font-weight: 500;
	letter-spacing: 2px;
	line-height: 30px;
	margin: 20px 0 10px 0;
}

/*-- END OF SERVICES SECTION STYLES --*/

/*-- START OF BACKGROUND IMAGE 1 STYLES --*/

.image-section-1 {
	background-image: url(/images/women-writing.jpg);
	background-size: cover;
	background-attachment: fixed;
	height: 500px;
}

/*-- END OF BACKGROUND IMAGE 1 STYLES --*/

/*-- START OF ABOUT SECTION STYLES --*/


#about {
	padding-top: 100px;
}
.bio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.awards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.award-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 350px;
	height: 450px;
}

.award-label {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 4px;
	text-align: center;
}

/*-- END OF ABOUT SECTION STYLES --*/

/*-- START OF TESTIMONIALS SECTION STYLES --*/

#testimonials {
	margin: 0 auto;
	padding: 120px 0;
	text-align: center;
}

.secondary-title-center {
	width: 100%;
}

.secondary-title-center h2 {
	margin-bottom: 40px;
}

.carousel-cell {
	width: 80%;	
	margin: 40px 120px;
}

.main-carousel:after {
	content: 'flickity';
	display: none;
	
}

.quote-small {
	margin-top: 60px;
}



/*-- START OF BACKGROUND IMAGE 2 STYLES --*/

.image-section-2 {
	background-image: url(/images/writing-coffee.jpg);
	background-size: cover;
	background-attachment: fixed;
	height: 500px;
}

/*-- END OF BACKGROUND IMAGE 2 STYLES --*/

/*-- START OF CONTACT SECTION STYLES --*/

#contact {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 700px;
}

.description a {
	color: #1f435f;
	font-size: 24px;
	text-decoration: none;
}

.social-large a {
	margin-right: 20px;
}

/*-- END OF CONTACT SECTION STYLES --*/

/*-- START OF FOOTER STYLES --*/

#footer {
	background-color: #4d727e;
	height: 150px;
	padding-top: 80px;
}

.copyright, .developer {
	color: #f4f7f7;
	text-align: center;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
}

.developer a {
	text-decoration: none;
	color: #e8e485;
}



/*-- END OF FOOTER STYLES --*/










